'use client'

import { serviceType } from '@/lib/service'
import { subcategoryType } from '@/lib/subcategory'
import Link from 'next/link'
import { usePathname, useSearchParams } from 'next/navigation'

type ParamsSubcategoryProps = {
  service: serviceType
  subcategories: subcategoryType[]
  title?: string
  style?: string
  center: boolean
}

export const ParamsSubcategory: React.FC<ParamsSubcategoryProps> = ({
  service,
  title,
  subcategories,
  center,
}) => {
  const currentPath = usePathname()
  // searchParam
  let hasCategoryId = false
  let hasSubcategoryId = false
  let hasTagId = false
  const searchParams = useSearchParams()
  const categoryId = searchParams.get('category_id')
  if (categoryId) {
    hasCategoryId = true
  }
  const subcategoryId = searchParams.get('subcategory_id')
  if (subcategoryId) {
    hasSubcategoryId = true
  }
  const tagId = searchParams.get('tag_id')
  if (tagId) {
    hasTagId = true
  }
  let path = currentPath
  if (hasCategoryId && hasSubcategoryId && hasTagId) {
    path = `${currentPath}?category_id=${categoryId}&subcategory_id=${subcategoryId}&tag_id=${tagId}`
  } else if (hasCategoryId && hasSubcategoryId) {
    path = `${currentPath}?category_id=${categoryId}&subcategory_id=${subcategoryId}`
  } else if (hasCategoryId && hasTagId) {
    path = `${currentPath}?category_id=${categoryId}&tag_id=${tagId}`
  } else if (hasSubcategoryId && hasTagId) {
    path = `${currentPath}?subcategory_id=${subcategoryId}&tag_id=${tagId}`
  } else if (hasCategoryId) {
    path = `${currentPath}?category_id=${categoryId}`
  } else if (hasSubcategoryId) {
    path = `${currentPath}?subcategory_id=${subcategoryId}`
  } else if (hasTagId) {
    path = `${currentPath}?tag_id=${tagId}`
  }
  return (
    <>
      <div className="horizontal-tab-title text-center mb-1">
        <h2 className="text-lg font-bold">
          {title ? title : 'カテゴリで絞る'}
        </h2>
      </div>
      <div
        className={
          'horizontal-tab-list inline-block overflow-x-auto flex sm:justify-center'
        }
        style={center ? { justifyContent: 'safe center' } : {}}
      >
        {subcategories.map((v, index) => (
          <Link
            key={index}
            href={
              path.includes('subcategory_id')
                ? path.replace(
                    /subcategory_id=[0-9]+/,
                    `subcategory_id=${v.subcategory_id}`,
                  )
                : path.includes('?')
                  ? `${path}&subcategory_id=${v.subcategory_id}`
                  : `${path}?subcategory_id=${v.subcategory_id}`
            }
            className="horizontal-tab-element font-semibold text-[13px] text-gray-600 hover:text-gray-800 hover:bg-gray-200 transition-colors duration-200 ease-in-out bg-[#efefef] rounded-[21px] px-4 py-2 m-1"
            style={{
              backgroundColor:
                v.subcategory_id === Number(subcategoryId)
                  ? service.main_color
                  : '#efefef',
              color: v.subcategory_id === Number(subcategoryId) ? '#fff' : '',
            }}
          >
            {v.name}
          </Link>
        ))}
      </div>
    </>
  )
}
