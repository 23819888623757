'use client'

import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  type CarouselApi,
} from '@/components/ui/carousel'
import { imageType } from '@/lib/screen'
import Image from 'next/image'
import Link from 'next/link'
import { useEffect, useState } from 'react'

type CarouselImageProps = {
  images: imageType[]
  style?: string
  fv?: boolean
}

export const ImageCarousel: React.FC<CarouselImageProps> = ({
  images,
  fv,
  style,
}) => {
  const [api, setApi] = useState<CarouselApi>()
  const [current, setCurrent] = useState(0)
  const [totalSlides, setTotalSlides] = useState(images.length)

  useEffect(() => {
    if (!api) {
      return
    }

    setTotalSlides(api.scrollSnapList().length)
    setCurrent(api.selectedScrollSnap())

    api.on("select", () => {
      setCurrent(api.selectedScrollSnap())
    })
  }, [api])

  return (
    <div className="relative">
      <Carousel
        className={`${style} w-full image-carousel sm:max-w-screen-xl sm:mx-auto sm:mt-4`}
        opts={{
          loop: true,
        }}
        setApi={setApi}
      >
        <CarouselContent>
          {images.map((v, index) => (
            <CarouselItem key={index} className="sm:basis-1/2">
              {v.link ? (
                <Link href={v.link}>
                  <Image
                    src={v.url}
                    alt={v.alt}
                    width={500}
                    height={200}
                    priority={0 === index ? fv : false}
                    quality={75}
                    sizes="(max-width: 640px) 100vw, 640px"
                    style={{ width: '100%' }}
                  />
                </Link>
              ) : (
                <Image
                  src={v.url}
                  alt={v.alt}
                  width={500}
                  height={200}
                  quality={75}
                  sizes="(max-width: 640px) 100vw, 640px"
                  priority={0 === index ? fv : false}
                  style={{ width: '100%' }}
                />
              )}
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselPrevious
          style={{
            left: 0,
            background: 'rgba(0,0,0,0.3)',
            border: 'none',
            color: '#fff',
          }}
        />
        <CarouselNext
          style={{
            right: 0,
            background: 'rgba(0,0,0,0.3)',
            border: 'none',
            color: '#fff',
          }}
        />

        {/* Dot indicators */}
        <div className="absolute bottom-4 left-0 right-0">
          <div className="flex justify-center gap-1">
            {Array.from({ length: totalSlides }).map((_, index) => (
              <button
                key={index}
                className={`w-2 h-2 rounded-full transition-colors ${
                  index === current ? 'bg-white' : 'bg-white/50'
                }`}
                onClick={() => api?.scrollTo(index)}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </Carousel>
    </div>
  )
}
