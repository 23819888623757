'use client'

import { serviceType } from '@/lib/service'
import Link from 'next/link'
import { usePathname, useSearchParams } from 'next/navigation'

// 水平タブリストのサブ実装
// 水平タブ内のリンクボタンのリンク先は、"現在のURL&paramName=paramValue"となる
export const ParamsCommon = ({
  service,
  title,
  center,
  paramName,
  paramValues,
}: {
  service: serviceType
  title: string
  center: boolean
  paramName: string
  paramValues: { title: string; paramValue: string }[]
}) => {
  const pathName = usePathname()
  const searchParams = useSearchParams()

  const currentParamValue = searchParams.get(paramName)

  const pageTo = (paramValue: string) => {
    const newParams = new URLSearchParams(searchParams.toString())
    newParams.set(paramName, paramValue)
    return `${pathName}?${newParams.toString()}`
  }

  return (
    <>
      <div className="horizontal-tab-title text-center mb-1">
        <h2 className="text-lg font-bold">{title}</h2>
      </div>
      <div
        className={
          'horizontal-tab-list inline-block overflow-x-auto flex ' +
          (center ? 'justify-center' : '')
        }
      >
        {paramValues.map((v, index) => {
          const isSelected = v.paramValue === currentParamValue
          return (
            <Link
              key={index}
              href={pageTo(v.paramValue)}
              className="horizontal-tab-element font-semibold text-[13px] text-gray-600 hover:text-gray-800 hover:bg-gray-200 transition-colors duration-200 ease-in-out bg-[#efefef] rounded-[21px] px-4 py-2 m-1"
              style={{
                backgroundColor: isSelected ? service.main_color : '#efefef',
                color: isSelected ? '#fff' : '',
              }}
            >
              {v.title}
            </Link>
          )
        })}
      </div>
    </>
  )
}
